.Licence-content {
  max-height: calc(100vh - 188px);
  overflow: auto;
}

.Licence-content .font-italic {
  font-style: italic;
}

.license-wrap {
  position: absolute;
  background-color: #fff;
  left: 0;
}
.license-container {
  min-height: 90vh;
  /* overflow: auto; */
  max-height: calc(100vh - 188px);
  font-family: ABBvoice;
  padding: 0px 15px;
  font-size: 13px;
  display: flex;
  /* overflow-x: hidden !important; */
}
.license-content {
  background-color: #fff;
  padding: 0px 25px;
  min-height: 98%;
  overflow: auto;
  width: 100%;
}
.license-container .license-information {
  font-weight: 600;
  font-size: 13px;
  /* margin-top: 10px;
  margin-bottom: 10px; */
  padding-top: 10px;
}

.dark-view-container .license-content {
  color: #fff;
  background-color: #333;
}
.license-container pre {
  word-wrap: normal;
  white-space: pre-line;
  word-break: normal;
}
