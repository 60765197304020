.file-upload-container {
  display: flex;
  padding-left: 10px;
  justify-content: flex-start;
}
.file-upload-button-wrap {
  margin-left: 20px;
}
.file-name-wrap {
  border: 1px solid #dbdbdb;
  height: 100%;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  min-height: 32px;
  flex-wrap: wrap;
}
.each-name-wrap {
  display: flex;
  background-color: #dbdbdb;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 4px;
}
.each-name-wrap .file-close-icon {
  margin-left: 5px;
  margin-top: 1px;
  cursor: pointer;
}

.upload-dialog .ABB_CommonUX_Dialog__content {
  width: 600px !important;
}
.submit-cancel-view-wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.submit-cancel-view-wrap button {
  margin-left: 10px;
}

.modal-submit-cancel {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px 10px 0px;
}
.modal-submit-cancel button {
  margin-left: 10px;
}
