.sorted-column {
  border-bottom: 4px solid #2e92fa !important;
}
td {
  overflow: unset !important;
  white-space: unset !important;
  text-overflow: unset !important;
  word-wrap: break-word;
  word-break: break-word;
}
.actions-cell {
  text-align: right;
  width: 50px;
}
.actions-header-column {
  width: 130px;
}
.click-class {
  cursor: pointer;
}
.checkBox-header-column {
  width: 30px;
}
.expand-header-column {
  width: 20px;
}
.expansion-cell {
  width: 20px;
  padding: 0px !important;
}
.expansion-cell i {
  margin-left: 10px;
  cursor: pointer;
}
.check-box-cell {
  width: 30px;
  padding: 0px !important;
}
.header-content-wrap {
  display: flex;
  justify-content: space-between;
}
.sortable-header {
  cursor: pointer;
}
.loading-container {
  min-height: 100px;
  display: flex;
  justify-content: center;
  margin-left: 50%;
  flex: 1;
  width: 100%;
}
.no-data-container {
  text-align: center !important;
}
.action-icons-container {
  display: flex;
}
.table-action-icon {
  cursor: pointer;
}
.action-icon-wrap {
  width: 40px;
}
.action-cell .action-disabled {
  pointer-events: none;
  opacity: 0.4;
}
.hidden-row-view {
  display: none;
}
.server-padding {
  padding-left: 20px;
}

tr:not(:last-child) .server-expansion-cell {
  border-bottom: none !important;
}
.edge-expansion-cell {
  border-top: 1px solid #bababa;
}
.faded-row-view td {
  border-bottom-color: #eaeaea !important;
}
.no-border-bottom td {
  border-bottom: none;
}
.status-view-wrap {
  display: flex;
}
.server-status-label {
  padding-top: 5px;
}
.disabled-status {
  opacity: 0.5;
}
.pop-up-view {
  height: 60px;
  width: 200px;
  position: absolute;
  z-index: 100;
  right: 120px;
  margin-top: 5px;
  /* background-color: #fff; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 6px 12px 1px;
}
.content-dark-theme .more-option-popup-row:hover {
  background-color: #333;
  cursor: pointer;
}

.more-option-popup-row {
  display: flex;
}
.light-theme .more-option-popup-row:hover {
  background-color: #eaeaea;
  cursor: pointer;
}
.more-option-popup-row div {
  margin-top: 7px;
}
.pop-up-view i {
  margin: 10px 10px 0px 5px;
}
.pop-up-arrow-light {
  height: 12px;
  width: 12px;
  position: absolute;
  background: rgb(255, 255, 255);
  transform: rotate(-45deg);
  z-index: 80;
  box-shadow: none;
  box-sizing: border-box;
  border-top: 1px solid rgb(235, 235, 235);
  border-right: 1px solid rgb(235, 235, 235);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 6px 12px 1px;
  right: 155px;
}
.pop-up-arrow-dark {
  height: 12px;
  width: 12px;
  position: absolute;
  background: #0f0f0f;
  transform: rotate(-45deg);
  z-index: 80;
  box-shadow: none;
  box-sizing: border-box;
  border-top: 1px solid #0f0f0f;
  border-right: 1px solid #0f0f0f;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 6px 12px 1px;
  right: 155px;
}
.pop-up-overlay {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 70;
}
