.servers-view-wrap {
  padding: 16px 16px 16px 16px;
  max-height: calc(100vh - 130px);
  overflow: auto;
  display: flex;
}
.servers-header-label {
  font-weight: 500 !important;
  padding-top: 2px;
}
.servers-header-container {
  padding: 5px 10px;
  border-radius: 3px;
}
.servers-body {
  margin-top: 15px;
  /* max-height: 390px;
  overflow: auto; */
}
.servers-container {
  padding: 40px 40px 10px 40px;
  height: 100%;
}
.server-header-label {
  font-weight: 700;
}
.servers-input-wrap {
  margin-top: 20px;
}
.servers-search-input {
  width: 280px;
}
.servers-grid-wrap {
  max-height: calc(100vh - 265px);
  overflow: auto;
}
