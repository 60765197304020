.header-wrap {
  background-color: #f5f5f5;
  text-align: center;
}
.pop-up-custom {
  position: absolute;
  z-index: 1000 !important;
  width: unset;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(235, 235, 235);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 6px 12px;
  padding: 0px;
  top: 80px;
  right: 60px;
  min-width: 100px;
  min-height: 50px;
}

.user-section {
  display: flex;
  align-items: center;
}
.username-label {
  padding-left: 5px;
}
hr {
  display: block;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden;
  border-style: inset;
  border-width: 1px;
}
.user-profile-wrap {
  opacity: 0;
  transition: opacity 3s;
}

.show-profile {
  transition: opacity 3s;
  opacity: 1;
}
.hide-prodile {
  transition: opacity 3s;
  opacity: 0;
}

.sign-out-link {
  cursor: pointer;
  display: flex;
}
.user-options {
  padding-left: 3px;
  cursor: pointer;
  display: flex;
}
.version-details {
  font-size: 14px;
  font-weight: 800;
}
.supported-browsers {
  font-size: 14px;
  font-weight: 800;
  margin-top: 10px;
  padding-bottom: 10px;
}
.roles-list-wrap {
  font-size: 14px;
  font-weight: 800;
  margin-top: 10px;
  padding-bottom: 10px;
}
.UserMenu__user-icon___2eQY7 {
  padding-right: 0px !important;
}
.UserMenu__user___35dyh {
  font-size: 14px !important;
}
.each-theme-image {
  /* height: 40px;
  width: 40px; */
}
.light-settings-view .each-theme-image {
  background-color: #fff;
}
.dark-settings-view .each-theme-image {
  background-color: #111;
}
.theme-settings-view {
  display: flex;
  margin-top: 20px;
}
.each-theme-view {
  background-color: #fff;
  padding: 6px 6px 0px 6px;
  cursor: pointer;
}
.selected-theme-view {
  padding: 6px 6px 0px 6px;
  background-color: #36f;
  pointer-events: none;
}
.each-theme-label {
  background-color: #fff;
  text-align: center;
}
.selected-theme-view .each-theme-label {
  background: #36f;
  color: #fff;
  border: 2px solid #36f;
}
.dark-theme-settings {
  background-color: #333 !important;
}
.dark-theme-settings
  .Heading__root___3LTN7.Heading__font-primary-color___3q3UG {
  color: #dbdbdb !important;
}
.dark-view-container
  .Heading__root___3LTN7.Heading__font-primary-color___3q3UG {
  color: #fff !important;
}
.selected-theme-view .each-theme-label {
  background: #36f;
  color: #fff;
  border: 2px solid #36f;
}
.un-selected-light {
  color: #111;
}
/* .app-top-navi-right .UserMenu__root___EXl-9-content {
  left: 957px !important;
  width: unset !important;
}
.app-top-navi-right .UserMenu__root___EXl-9-arrow {
  right: 66px !important;
} */
