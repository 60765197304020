.common-loader-wrap {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999 !important;
  /* background-color: #b0b0b0;*/
  /* opacity: 0.2;
  background: rgba(51, 51, 51, 0.5); */
}
.loader-mask {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 99990 !important;
  opacity: 0.4;
  background: rgba(51, 51, 51, 0.5);
}
.theme-loader-mask {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 99990 !important;
  background: rgb(255, 255, 255);
}
.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial
  > div
  > svg
  > g
  > circle.ABB_CommonUX_LoadingIndicator__back {
  stroke: #36f;
}
.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__dark.ABB_CommonUX_LoadingIndicator__radial
  > div
  > svg
  > g
  > .ABB_CommonUX_LoadingIndicator__back {
  stroke: #36f;
}
